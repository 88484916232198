"use strict";

(function () {
  const app = {
    init() {
      svg4everybody();
    }
  };
  
  const form = {
    init() {
      this.inputTel();
  
      this.setup();
      this.validation();
      this.sending();
    },
  
    inputTel() {
      // https://github.com/uNmAnNeR/imaskjs
      const elems = document.querySelectorAll('.js-tel-mask');
  
      if (!elems.length) return;
  
      elems.forEach(el => {
        let mask = IMask(el, {
          mask: el.dataset.mask
          // lazy: false
        });
  
        el.addEventListener('focus', e => {
          mask.updateOptions({ lazy: false });
        });
  
        el.addEventListener('blur', e => {
          mask.updateOptions({ lazy: true });
          el.setAttribute('data-value', mask.unmaskedValue);
        });
      });
    },
  
    setup() {
      this.bouncerSettings = {
        messageAfterField: true,
        messages: {
          missingValue: {
            checkbox: 'Обязательное поле',
            radio: 'Выберите значение',
            select: 'Выберите значение',
            'select-multiple': 'Выберите значение',
            default: 'Обязательное поле'
          },
          patternMismatch: {
            email: 'Не верный формат e-mail',
            default: 'Проверьте формат значения'
          },
          phoneNum: 'Введите верный номер'
        },
        disableSubmit: true,
        customValidations: {
          phoneNum: (field) => {
            if (field.type == 'tel') {
              const pattern = /^\+7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}$/;
              let test = pattern.test(field.value);
              if (!test) return true;
            }
  
            return false;
          }
        }
      }
    },
  
    validation() {
      if (typeof Bouncer === 'undefined') return;
  
      window.AppBouncer = new Bouncer('[data-bouncer]', this.bouncerSettings);
  
      document.addEventListener('bouncerRemoveError', e => {
        const field = e.target;
        field.classList.add('is-valid');
      }, false);
  
      document.addEventListener('bouncerShowError', e => {
        const field = e.target;
        field.classList.remove('is-valid');
      }, false);
    },
  
    sending() {
      document.addEventListener('bouncerFormValid', e => {
        const form = e.target;
  
        if (form.hasAttribute('method')) {
          return;
        }
  
        const tel = form.querySelector('[name="tel"]');
        const mouse = form.querySelector('[name="mouse"]');
  
        if (mouse.value) {
          return;
        }
  
        const link = 'https://api.whatsapp.com/send?phone=7' + tel.getAttribute('data-value');
  
        const link_el = form.querySelector('.js-link');
        link_el.setAttribute('href', link);
  
        form.reset();
  
        link_el.click();
  
        return;
      }, false);
    }
  };
  

  document.addEventListener('DOMContentLoaded', () => {
    app.init();
    form.init();
  });
}());
